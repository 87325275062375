<template>
  <div class="od-modal">
    <div class="od-modal__inner">
      <img
        src="/v2/modal/open-doors/close.svg"
        alt="close"
        class="od-modal__close"
        @click="$store.dispatch('modals/closeNabor24')"
      >

      <h6 class="od-modal__title">
        Успейте записаться на самых выгодных условиях! <br> Оставляйте заявку
      </h6>

      <div class="od-modal__fields">
        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="Имя"
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          placeholder="Почта"
          @clearSubmitError="clearSubmitError"
        />
      </div>

      <button
        class="od-modal__btn"
        @click="submit"
      >
        Отправить
      </button>

      <p class="od-modal__law">
        Нажимая на кнопку, вы соглашаетесь
        <br>
        <nuxt-link
          to="/privacy"
          target="_blank"
        >
          c условиями обработки данных
        </nuxt-link>
      </p>

      <div
        v-if="!cenyPage"
        class="od-modal__social"
      >
        <p>
          Или напишите нам в чат:
        </p>

        <div class="od-modal__social-items">
          <a
            href="https://wa.me/79686286327"
            target="_blank"
            rel="nofollow"
            class="whatsapp"
          >
            <img
              src="/v2/modal/open-doors/whatsapp.svg"
              alt="whatsapp"
            >
          </a>
          <a
            href="https://t.me/schoolsynergy_bot"
            target="_blank"
            rel="nofollow"
            class="telegram"
          >
            <img
              src="/v2/modal/open-doors/telegram.svg"
              alt="telegram"
            >
          </a>
          <a
            href="mailto:nstolbova@synergy.ru"
            target="_blank"
            rel="nofollow"
            class="email"
          >
            <img
              src="/v2/modal/open-doors/email.svg"
              alt="email"
            >
          </a>
        </div>
      </div>
    </div>

    <div
      class="od-modal__overlay"
      @click="$store.dispatch('modals/closeNabor24')"
    />
  </div>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
});

export default {
  name: 'Nabor24Modal',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  data: () => ({
    landCode: 'new_online_school_nabor_24_25_banner',
  }),

  computed: {
    cenyPage() {
      return this.$route.name === 'online-school-ceny';
    },
  },
};
</script>

<style lang="scss" scoped>
.od-modal {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    width: 545px;
    max-height: 98vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 40px 40px 32px 40px;
    position: relative;
    z-index: 1;

    border-radius: 20px;
    border: none;
    box-shadow: 4px 4px 20px 0 rgba($ege-blue-darkest, .05), 10px 10px 50px 0 rgba($ege-blue-darkest, .1);
    background: $white-color;

    @include media-down(tablet) {
      width: 100%;
      max-width: 344px;
      padding: 40px 8px;
    }
  }

  &__close {
    position: absolute;
    right: 8px;
    top: 8px;

    cursor: pointer;

    @include media-down(tablet) {
      right: 0;
      top: 0;
    }
  }

  &__title {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.2px;
    color: $black-color;

    @include media-down(tablet) {
      padding: 0;
      font-size: 24px;
      letter-spacing: -0.48px;
    }

    ::v-deep & > span {
      display: inline-block;
      position: relative;

      &:before {
        display: none;
      }

      span {
        position: relative;
        z-index: 2;
        color: $ege-orange;
      }
    }
  }

  &__fields {
    margin-top: 32px;

    @include media-down(tablet) {
      margin-top: 24px;
    }

    .form__input {
      margin: 0 auto 10px;

      @include media-down(tablet) {
        margin-bottom: 8px;
      }

      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        height: auto;
        padding: 24.25px 18px;

        border-radius: 100px;
        border: 1px solid rgba($black-color, .2);

        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 1;
        color: $black-color;

        &::placeholder {
          color: $grey2;
        }

        @include media-down(tablet) {
          padding: 21.75px 14px;

          font-size: 16px;
        }
      }

      ::v-deep .phone-field__input {
        padding-top: 20px;
        padding-bottom: 22px;

        font-size: 20px;
        font-weight: 400;
        letter-spacing: 0.4px;

        @include media-down(tablet) {
          font-size: 16px;
        }
      }

      ::v-deep .phone-field__wrap {
        height: 74px;
        padding: 24px 20px 26px 20px;

        border-radius: 100px;
        border: 1px solid rgba($black-color, .2);

        @include media-down(tablet) {
          height: 64px;
        }

        input {
          border-radius: inherit;

          &::placeholder {
            color: $grey2;
          }
        }

        &--error {
          border-color: #ff0040;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 74px;
    margin-top: 20px;

    border-radius: 100px;
    background-color: $ege-orange;
    border: 2px solid $brown-dark;
    box-shadow: 0 4px 0 0 $brown-dark;

    font-family: 'TildaSans', sans-serif;
    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 30px;
    font-weight: 700;
    line-height: normal;
    color: $white-color;

    cursor: pointer;
    transition: transform .2s ease-out, box-shadow .2s ease-out;

    @include media-down(tablet) {
      margin-top: 8px;
      height: 64px;
      font-size: 24px;
    }

    &:hover {
      transform: translate(-4px, -4px);
      box-shadow: 4px 4px 0 0 $black-color;
    }
  }

  &__law {
    max-width: 384px;
    margin: 10px auto 0;

    text-align: center;
    font-variant-numeric: lining-nums proportional-nums;
    font-size: 18px;
    font-weight: 600;
    line-height: 100%;
    color: $grey2;

    @include media-down(tablet) {
      margin-top: 8px;

      font-size: 16px;
      line-height: 120%;
    }

    a {
      color: $ege-orange;
    }
  }

  &__social {
    margin-top: 32px;

    @include media-down(tablet) {
      margin-top: 24px;
    }

    p {
      font-variant-numeric: lining-nums proportional-nums;
      font-weight: 500;
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      color: $black-color;

      @include media-down(tablet) {
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
      }
    }

    &-items {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 16px;

      @include media-down(tablet) {
        margin-top: 12px;
      }

      a {
        display: inline-block;
        width: 50px;
        height: 50px;
        overflow: hidden;

        border-radius: 10px;
        transform: translate(-2px, -2px);

        transition: transform .2s ease-out, box-shadow .2s ease-out;

        img {
          width: 100%;
        }
      }
      a.whatsapp {
        border: 2px solid #168B25;
        box-shadow: 2px 2px 0 0 #168B25;

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 #168B25;
        }
      }
      a.telegram {
        border: 2px solid #016EA3;
        box-shadow: 2px 2px 0 0 #016EA3;

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 #016EA3;
        }
      }
      a.email {
        border: 2px solid #044396;
        box-shadow: 2px 2px 0 0 #044396;

        &:hover {
          transform: translate(0, 0);
          box-shadow: 0 0 0 0 #044396;
        }
      }
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($black-color, 0.3);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);

    cursor: pointer;
  }
}
</style>
