/**
 * Проверка на наличие только букв (латиница и крилица)
 *
 * @param value Проверяемое значение
 * @return {boolean}
 */
export function isOnlyChars(value) {
  return /^[ a-zA-Zа-яА-ЯЁё]*$/.test(value);
}

export function validEmail(email) {
  // eslint-disable-next-line max-len,vue/max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export default { isOnlyChars, validEmail };
