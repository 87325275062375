<template>
  <div
    class="video-modal"
    @click="closeVideoModal"
  >
    <div
      class="video-modal__inner"
      @click.stop
    >
      <button
        class="m-btn video-modal__close"
        @click="closeVideoModal"
      >
        <img
          :src="closeIconPath"
          alt="close"
        >
      </button>

      <iframe
        v-if="isYoutubeVideo"
        class="video-modal__video"
        :src="videoSrc"
        frameborder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; web-share"
        allowfullscreen
      />
      <video
        v-else
        :src="videoSrc"
        class="video-modal__video"
        controls
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'VideoModal',
  props: {
    closeIconPath: {
      type: String,
      default: '/v2/modal/close.svg',
    },
  },
  computed: {
    ...mapGetters('modals', {
      videoSrc: 'videoModalSrc',
    }),
    isYoutubeVideo() {
      return this.videoSrc.includes('youtu');
    },
  },
  methods: {
    ...mapActions('modals', {
      closeVideoModal: 'closeVideo',
    }),
  },
};
</script>

<style scoped lang="scss">

.video-modal {
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba($white-color, 0.6);
  }

  .video-modal__inner {
    position: relative;
    z-index: 2;
    width: min(1070px, calc(100% - 32px));
    border-radius: 20px;
    border: 2px solid $black;
    box-shadow: 4px 4px 0 0 $black;
    background-color: $black-color;
    cursor: default;
    overflow: hidden;
  }

  .video-modal__close {
    position: absolute;
    z-index: 3;
    right: 20px;
    top: 20px;

    img {
      border-radius: 50px;
      border: 2px solid $black;
      background-color: $white-color;
      box-shadow: 2px 2px 0 0 $black;

      transition: 200ms;

      &:hover {
        box-shadow: none;
        transform: translate(2px, 2px);
      }
    }
  }

  .video-modal__video {
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: contain;
    background-color: $black-color;
    //height: 100%;
  }
}

</style>
