import { SUBJECTS } from '@/data/constants';
/* eslint-disable key-spacing, quote-props */

export default {
  // route name
  'index':                                 'new_online_school_main',
  'online-school':                         'new_online_school_school',
  'ege':                                   'new_online_school_ege',
  'oge':                                   'new_online_school_oge',
  'externat':                              'new_online_school_externat',
  'repetitor':                             'new_online_school_repetitor',
  'spartak':                               'new_online_school_spartak',
  'gazprom-bonus':                         'new_online_school_gazprom_bonus',
  'halloween':                             'new_online_school_halloween',
  'soft-skills':                           'new_online_school_soft_skills',
  'itogovoe-sochinenie':                   'new_online_school_itogovoe_sochinenie',
  'itogovoe-sobesedovanie-2024':           'new_online_school_itogovoe_sobesedovanie_2024',
  'rkvk':                                  'new_online_school_rk_vk',
  'happy-new-year-2024':                   'new_online_school_happy_new_year_2024',
  'marathon-ege-2024':                     'new_online_school_marathon_ege_2024',
  'marathon-ege-matematika-2024':          'new_online_school_marathon_ege_matematika_2024',
  'marathon-ege-russkij-2024':             'new_online_school_marathon_ege_russkij_2024',
  'marathon-ege-2024-drb':                 'new_online_school_marathon_ege_2024_drb',
  'repetitor-7-klass-russkij-yazyk':       'new_online_school_repetitor_7_klass_russkij-yazyk',
  'promo-russkij-yazyk-polugod':           'new_online_school_promo_russkij_yazyk_polugod',
  'promo-matematika-profil-polugod':       'new_online_school_promo_matematika_profil_polugod',
  'events-open-day-21-03-2024':            'new_online_school_events_dod_21_03_2024',
  'events-open-day-08-04-2024':            'new_online_school_events_dod_08_04_2024',
  'events-open-day-26-04-proforientaciya': 'new_online_school_events_dod_26_04_proforientaciya',
  'events-open-day-26-04-stress-bulling':  'new_online_school_events_dod_26_04_stress_bulling',
  'events-open-day-26-04-2024':            'new_online_school_events_dod_26_04_2024',
  'intensiv-oge':                          'new_online_school_intensiv_oge',
  'intensiv-ege':                          'new_online_school_intensiv_ege',
  'events-open-day-26-04-olimpiada':       'new_online_school_events_dod_26_04_olimpiada',
  'promo-9-may':                           'new_online_school_promo_9_may',
  'online-school-ceny':                    'new_online_school_school_ceny',
  'online-school-ceny-poddomen':           'new_online_school_ceny_poddomen',
  'repetitor-anglijskij-yazyk':            'new_online_school_repetitor_anglijskij_yazyk',
  'repetitor-matematika':                  'new_online_school_repetitor_matematika',
  'repetitor-himiya':                      'new_online_school_repetitor_himiya',
  'repetitor-biologiya':                   'new_online_school_repetitor_biologiya',
  'repetitor-fizika':                      'new_online_school_repetitor_fizika',
  'repetitor-russkij-yazyk':               'new_online_school_repetitor_russkij-yazyk',
  'repetitor-obshchestvoznanie':           'new_online_school_repetitor_obshchestvoznanie',
  'repetitor-informatika':                 'new_online_school_repetitor_informatika',
  'repetitor-istoriya':                    'new_online_school_repetitor_istoriya',
  'proforientacia':                        'new_online_school_proforientacia',
  'school-university':                     'new_online_school_school_university',
  'thanks-shus':                           'new_online_school_thanks_shus',
  'ege-ceny':                              'new_online_school_ege_ceny',
  'oge-ceny':                              'new_online_school_oge_ceny',
  'otzyvy':                                'new_online_school_otzyvy',
  'catalog-repetitor':                     'new_online_school_catalog_repetitor',
  'catalog-repetitor-first':               'new_online_school_catalog_repetitor',
  'catalog-repetitor-second':              'new_online_school_catalog_repetitor',
  'catalog-repetitor-third':               'new_online_school_catalog_repetitor',
  'catalog-programmirovanie-dlya-detej':              'new_online_school_catalog_programmirovanie',
  'catalog-programmirovanie-dlya-detej-first':        'new_online_school_catalog_programmirovanie',
  'catalog-programmirovanie-dlya-detej-first-second': 'new_online_school_catalog_programmirovanie',
  'externat-dlya-vzroslyh':                           'new_online_school_externat_dlya_vzroslyh',
  'online-school-partners':                           'new_online_school_school_partners',
  'online-school-zaochnoe-obuchenie':                 'new_online_school_school_zaochnoe',
  'online-school-attestacii-i-prikreplenie':          'new_online_school_school_attestacii',
  'online-school-vechernee-obuchenie':                'new_online_school_vechernee_obuchenie',
  'online-school-semeinoe-obuchenie':                 'new_online_school_semeinoe_obuchenie',
  'online-school-5-klass':                            'new_online_school_school_5',
  'online-school-6-klass':                            'new_online_school_school_6',
  'online-school-7-klass':                            'new_online_school_school_7',
  'online-school-8-klass':                            'new_online_school_school_8',
  'online-school-9-klass':                            'new_online_school_school_9',
  'online-school-10-klass':                           'new_online_school_school_10',
  'online-school-11-klass':                           'new_online_school_school_11',

  // route path
  [`/oge/${SUBJECTS.matematika}`]:        'new_online_school_oge_matematika',
  [`/oge/${SUBJECTS.russkijYazyk}`]:      'new_online_school_oge_russkij-yazyk',
  [`/oge/${SUBJECTS.anglijskijYazyk}`]:   'new_online_school_oge_anglijskij-yazyk',
  [`/oge/${SUBJECTS.informatika}`]:       'new_online_school_oge_informatika',
  [`/oge/${SUBJECTS.obshchestvoznanie}`]: 'new_online_school_oge_obshchestvoznanie',
  [`/oge/${SUBJECTS.istoriya}`]:          'new_online_school_oge_istoriya',
  [`/oge/${SUBJECTS.fizika}`]:            'new_online_school_oge_fizika',
  [`/oge/${SUBJECTS.himiya}`]:            'new_online_school_oge_himiya',
  [`/oge/${SUBJECTS.biologiya}`]:         'new_online_school_oge_biologiya',

  [`/ege/${SUBJECTS.matematika}`]:         'new_online_school_ege_matematika',
  [`/ege/${SUBJECTS.matematikaProfil}`]:  'new_online_school_ege_matematika-profil',
  [`/ege/${SUBJECTS.russkijYazyk}`]:      'new_online_school_ege_russkij-yazyk',
  [`/ege/${SUBJECTS.anglijskijYazyk}`]:   'new_online_school_ege_anglijskij-yazyk',
  [`/ege/${SUBJECTS.informatika}`]:        'new_online_school_ege_informatika',
  [`/ege/${SUBJECTS.istoriya}`]:           'new_online_school_ege_istoriya',
  [`/ege/${SUBJECTS.obshchestvoznanie}`]:  'new_online_school_ege_obshchestvoznanie',
  [`/ege/${SUBJECTS.biologiya}`]:          'new_online_school_ege_biologiya',
  [`/ege/${SUBJECTS.fizika}`]:             'new_online_school_ege_fizika',
  [`/ege/${SUBJECTS.himiya}`]:             'new_online_school_ege_himiya',
  '/free/ege/kurs-ege-2024': 'new_online_school_free_ege_kurs_ege_2024',

  '/class/5':               'school_synergy_ru_5',
  '/class/6':               'school_synergy_ru_6',
  '/class/7':               'school_synergy_ru_7',
  '/class/8':               'school_synergy_ru_8',
  '/class/9':               'school_synergy_ru_9',
  '/class/10':              'school_synergy_ru_10',
  '/class/11':              'school_synergy_ru_11',
};
