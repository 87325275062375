<template>
  <div class="od-modal">
    <div class="od-modal__inner">
      <span class="od-modal__cheap od-modal__date">с 14.09-22.09</span>
      <span class="od-modal__cheap od-modal__all">all inclusive</span>

      <h6 class="od-modal__title">
        поступите в&nbsp;школу и&nbsp;выиграйте поездку на&nbsp;неделю в&nbsp;Анапу
      </h6>

      <p class="od-modal__subtitle">
        сотрудник отдела заботы расскажет все&nbsp;подробности
      </p>

      <div class="od-modal__fields">
        <form-name-field
          ref="nameField"
          v-model="nameInput"
          class="form__input"
          placeholder="как вас зовут?"
        />

        <form-phone-field
          ref="phoneField"
          class="form__input form__input_phone"
          :errors-list="submitError"
          :error="validForm === false"
          placeholder="ваш номер телефона"
          @updatePhone="phoneInput = $event"
        />

        <form-email-field
          ref="emailField"
          v-model="emailInput"
          class="form__input form__input_email"
          :submit-error="submitError"
          placeholder="ваша почта"
          @clearSubmitError="clearSubmitError"
        />
      </div>

      <!-- eslint-disable -->
      <p class="od-modal__law">
        нажимая на кнопку, вы соглашаетесь
        с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >условиями обработки данных</nuxt-link>
        в&nbsp;соответствии
        с&nbsp;<nuxt-link
        to="/privacy"
        target="_blank"
      >политикой конфиденциальности</nuxt-link>
      </p>
      <!-- eslint-enable -->

      <button
        class="m-btn od-modal__btn"
        @click="submit"
      >
        оставить заявку
      </button>
    </div>

    <div
      class="od-modal__overlay"
      @click="$store.dispatch('modals/closeAnapa24')"
    />
  </div>
</template>

<script>
import FormNameField from '@/components/v2/common/fileds/FormNameField.vue';
import FormEmailField from '@/components/v2/common/fileds/FormEmailField.vue';
import FormPhoneField from '@/components/v2/common/fileds/FormPhoneField.vue';
import sendLeadMixinConstructor from '@/mixins/sendLeadMixinConstructor';

const sendLeadMixin = sendLeadMixinConstructor({
  needPhone: true,
  needEmail: true,
});

export default {
  name: 'Anapa24Modal',
  components: {
    FormPhoneField,
    FormEmailField,
    FormNameField,
  },
  mixins: [sendLeadMixin],

  data: () => ({
    landCode: 'new_online_school_nabor_leto_24_25_banner',
  }),
};
</script>

<style lang="scss" scoped>
.od-modal {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__inner {
    position: relative;
    width: calc(var(--scale) * 720);
    max-height: 98vh;
    overflow: hidden;
    box-sizing: border-box;
    padding: calc(var(--scale) * 32) calc(var(--scale) * 40) calc(var(--scale) * 40);
    z-index: 1;

    border-radius: calc(var(--scale) * 60);
    border: none;
    box-shadow:
      calc(var(--scale) * 4) calc(var(--scale) * 4) calc(var(--scale) * 20) 0 rgba($ege-blue-darkest, .05),
      calc(var(--scale) * 10) calc(var(--scale) * 10) calc(var(--scale) * 50) 0 rgba($ege-blue-darkest, .1);
    background-color: $white-color;

    font-family: 'Onest', sans-serif;

    @include media-down($size-tablet) {
      width: 100%;
      max-width: calc(var(--scale) * 366);
      border-radius: calc(var(--scale) * 32);
      padding: calc(var(--scale) * 20) calc(var(--scale) * 16) calc(var(--scale) * 8);
    }
  }

  &__cheap {
    position: absolute;
    padding: calc(var(--scale) * 12) calc(var(--scale) * 20) calc(var(--scale) * 13);
    border-radius: calc(var(--scale) * 40);

    font-weight: 500;
    font-size: calc(var(--scale) * 26);
    line-height: 120%;

    @include media-down($size-tablet) {
      padding: calc(var(--scale) * 4) calc(var(--scale) * 10) calc(var(--scale) * 6);
      font-size: calc(var(--scale) * 14);
    }
  }

  &__date {
    top: calc(var(--scale) * 18);
    right: calc(var(--scale) * 23);
    background-color: #5237E6;
    transform: rotate(4deg);
    color: #FFFFFF;

    @include media-down($size-tablet) {
      transform: rotate(12deg);
      top: calc(var(--scale) * 7);
      right: calc(-1 * var(--scale) * 4);
    }
  }

  &__all {
    top: calc(var(--scale) * 70);
    right: calc(var(--scale) * 23);
    transform: rotate(9deg);
    background-color: #E6CEFF;
    color: #5237E6;

    @include media-down($size-tablet) {
      top: calc(var(--scale) * 33);
      right: calc(var(--scale) * 3);
      transform: rotate(5deg);
    }
  }

  &__title {
    margin-bottom: calc(var(--scale) * 12);

    font-weight: 500;
    font-size: calc(var(--scale) * 48);
    line-height: 110%;
    letter-spacing: -0.02em;
    color: #02120F;

    @include media-down($size-tablet) {
      margin-bottom: calc(var(--scale) * 8);
      font-size: calc(var(--scale) * 28);
      line-height: 95%;
    }
  }

  &__subtitle {
    margin-bottom: calc(var(--scale) * 20);

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      font-size: calc(var(--scale) * 16);
      letter-spacing: -0.02em;
    }
  }

  &__fields {
    width: calc(100% + (var(--scale) * 20));
    margin-left: calc(-1 * var(--scale) * 10);
    margin-bottom: calc(var(--scale) * 20);
    display: flex;
    flex-direction: column;
    gap: calc(var(--scale) * 8);

    @include media-down($size-tablet) {
      width: 100%;
    }

    .form__input {
      ::v-deep input:not(.phone-field__input) {
        width: 100%;
        height: auto;
        padding: calc(var(--scale) * 23) calc(var(--scale) * 32) calc(var(--scale) * 24);

        border-radius: 100px;
        border: 1px solid rgba(#A7ABAC, .5);

        font-size: calc(var(--scale) * 26);
        font-weight: 500;
        line-height: 1;
        color: $black-color;

        @include media-down($size-tablet) {
          padding-block: calc(var(--scale) * 22) calc(var(--scale) * 23);
          font-size: calc(var(--scale) * 18);
          letter-spacing: -0.02em;
        }

        &::placeholder {
          color: rgba(#A7ABAC, 0.5);
        }
      }

      ::v-deep .phone-field__input {
        padding-top: calc(var(--scale) * 20);
        padding-bottom: calc(var(--scale) * 22);

        font-size: calc(var(--scale) * 26);
        font-weight: 500;

        @include media-down($size-tablet) {
          font-size: calc(var(--scale) * 18);
        }
      }

      ::v-deep .phone-field__wrap {
        height: calc(var(--scale) * 80);
        padding: calc(var(--scale) * 23) calc(var(--scale) * 32) calc(var(--scale) * 24) calc(var(--scale) * 16);
        border-radius: 100px;
        border: 1px solid rgba(#A7ABAC, .5);

        @include media-down($size-tablet) {
          height: calc(var(--scale) * 68);
        }

        input {
          border-radius: inherit;

          &::placeholder {
            color: rgba(#A7ABAC, 0.5);
          }
        }

        &--error {
          border-color: #ff0040;
        }
      }
    }
  }

  &__law {
    margin-bottom: calc(var(--scale) * 40);

    font-weight: 400;
    font-size: calc(var(--scale) * 20);
    line-height: 130%;
    color: #A7ABAC;

    @include media-down($size-tablet) {
      font-weight: 500;
      font-size: calc(var(--scale) * 14);
    }

    a {
      color: #FF9C12;
    }
  }

  &__btn {
    width: 100%;
    padding-block: calc(var(--scale) * 36);
    background-color: #5237E6;
    border-radius: calc(var(--scale) * 24);

    font-weight: 500;
    font-size: calc(var(--scale) * 32);
    line-height: 110%;
    color: #FFFFFF;

    @include media-down($size-tablet) {
      width: calc(100% + (var(--scale) * 16));
      margin-left: calc(-1 * var(--scale) * 8);
      padding-block: calc(var(--scale) * 30);
      box-shadow:
        calc(var(--scale) * 10) calc(var(--scale) * 10) calc(var(--scale) * 50) rgba(7, 21, 57, 0.1),
        calc(var(--scale) * 4) calc(var(--scale) * 4) calc(var(--scale) * 20) rgba(7, 21, 57, 0.05);
      border-radius: calc(var(--scale) * 24);
      font-size: calc(var(--scale) * 18);
    }

    &:hover {
      background-color: #846EFB;
    }
  }

  &__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba($black-color, 0.3);
    -webkit-backdrop-filter: blur(2.5px);
    backdrop-filter: blur(2.5px);

    cursor: pointer;
  }
}
</style>
