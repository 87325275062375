export const SUBJECTS = {
  matematika: 'matematika',
  matematikaProfil: 'matematika-profil',
  russkijYazyk: 'russkij-yazyk',
  anglijskijYazyk: 'anglijskij-yazyk',
  informatika: 'informatika',
  obshchestvoznanie: 'obshchestvoznanie',
  istoriya: 'istoriya',
  fizika: 'fizika',
  himiya: 'himiya',
  biologiya: 'biologiya',
  literatura: 'literatura',
};

/**
 * Наименования предметов
 */
export const SUBJECT_NAME = {
  [SUBJECTS.istoriya]: 'История',
  khimiya: 'Химия',
  [SUBJECTS.fizika]: 'Физика',
  [SUBJECTS.literatura]: 'Литература',
  algebra: 'Алгебра',
  math: 'Математика',
  geografiya: 'География',
  russkiy: 'Русския язык',
  anglisky: 'Английский язык',
  obschestvoznanie: 'Обществознание',
  geometria: 'Геометрия',
  [SUBJECTS.informatika]: 'Информатика',
  [SUBJECTS.biologiya]: 'Биология',
  oge: 'ОГЕ', // Подготовка  ОГЕ
  ege: 'ЕГЕ', // Подготовка  ОГЕ
};

/**
 * Карта иконок предметов
 */
export const SUBJECT_ICON_MAP = {
  math: 'math.svg',
  math_prof: 'math-prof.svg',
  russkiy: 'russkiy.svg',
  fizika: 'fizika.svg',
  informatika: 'informatika.svg',
  khimiya: 'chimya.svg',
  biologiya: 'biologya.svg',
  geografiya: 'geographya.svg',
  obschestvoznanie: 'obchestvoznanie.svg',
  istoriya: 'istorya.svg',
  anglisky: 'angliskyi.svg',
  literatura: 'literatura.svg',
  algebra: 'algebra.svg',
  geometria: 'geometry.svg',
};

// Дополнительные курсы для классов
export const ADDITIONAL_COURSES = [
  {
    schoolClass: 5,
    subjects: [
      { title: '«English Speaking Club» LVL1', img: 'english' },
      { title: 'Вокруг света', img: 'around_world' },
      { title: 'Художество', img: 'art' },
      { title: 'Русская фразеология', img: 'russian_phraseology' },
      { title: 'Финансовая грамотность', img: 'economy' },
      { title: 'Я исследователь', img: 'study' },
      { title: 'Мастерская здорового тела', img: 'healthy_body' },
    ],
  },
  {
    schoolClass: 6,
    subjects: [
      { title: '«English Speaking Club» LVL1', img: 'english' },
      { title: 'Вокруг света', img: 'around_world' },
      { title: 'Художество', img: 'art' },
      { title: 'Русская фразеология', img: 'russian_phraseology' },
      { title: 'Финансовая грамотность', img: 'economy' },
      { title: 'Я исследователь', img: 'study' },
      { title: 'Мастерская здорового тела', img: 'healthy_body' },
      { title: 'О музах и музыке', img: 'music' },
    ],
  },
  {
    schoolClass: 7,
    subjects: [
      { title: '«English Speaking Club» LVL2', img: 'english' },
      { title: 'Книжный клуб', img: 'book_club' },
      { title: 'Вокруг света', img: 'around_world' },
      { title: 'Художество', img: 'art' },
      { title: 'Русская фразеология', img: 'russian_phraseology' },
      { title: 'Финансовая грамотность', img: 'economy' },
      { title: 'Я исследователь', img: 'study' },
      { title: 'Мастерская здорового тела', img: 'healthy_body' },
      { title: 'О музах и музыке', img: 'music' },
    ],
  },
  {
    schoolClass: 8,
    subjects: [
      { title: '«English Speaking Club» LVL2', img: 'english' },
      { title: 'Книжный клуб', img: 'book_club' },
      { title: 'Страноведение Испании', img: 'spain' },
      { title: 'Финансовая грамотность', img: 'economy' },
      { title: 'Я исследователь', img: 'study' },
      { title: 'Мастерская здорового тела', img: 'healthy_body' },
      { title: 'О музах и музыке', img: 'music' },
      { title: 'Алгебра. Дополнительные главы', img: 'algebra' },
    ],
  },
  {
    schoolClass: 9,
    subjects: [
      { title: '«English Speaking Club» LVL2', img: 'english' },
      { title: 'Книжный клуб', img: 'book_club' },
      { title: 'Страноведение Испании', img: 'spain' },
      { title: 'О музах и музыке', img: 'music' },
      { title: 'Алгебра. Дополнительные главы', img: 'algebra' },
      { title: 'Программирование на языке Python', img: 'python' },
    ],
  },
  {
    schoolClass: 10,
    subjects: [
      { title: '«English Speaking Club» LVL2', img: 'english' },
      { title: 'Книжный клуб', img: 'book_club' },
      { title: 'Страноведение Испании', img: 'spain' },
      { title: 'Алгебра. Дополнительные главы', img: 'algebra' },
      { title: 'Программирование на языке Python', img: 'python' },
    ],
  },
  {
    schoolClass: 11,
    subjects: [
      { title: '«English Speaking Club» LVL2', img: 'english' },
      { title: 'Книжный клуб', img: 'book_club' },
      { title: 'Страноведение Испании', img: 'spain' },
      { title: 'Программирование на языке Python', img: 'python' },
    ],
  },
];

export const QUESTIONNARIE_NAMES = {
  common_questionnarie: 'common_questionnarie',
  ege_questionnarie: 'ege_questionnarie',
};
